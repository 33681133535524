import React from 'react'
import { ImageSlider } from '../'

const Separator = (props) => {
  const style = {
    between : {
      minHeight: "65vh",
      display: "contents",
      alignItems: "center",
      backgroundSize: "cover",
      backgroundAttachment: "fixed",
      backgroundPosition: "bottom center"
    }
  }
  return(
    <section style={style.between} className={`background-fixed`} key={props.id} id={`#${props.id}`} >
      <div>
        <ImageSlider slides={props.restaurantStorys} />
      </div>
    </section>
  )
}

export default (Separator)
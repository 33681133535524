import React, { useState, useEffect} from 'react'
import { useTranslation } from 'react-i18next'
import { isRestaurantOpen } from '../../utils/dateHelper'
import background from '../../images/hero.jpg'

const HeroSection = (props) => {
  const {t} = useTranslation()

  const [offset, setOffset] = useState(0)
  const style = {
    hero : {
      width: "100%",
      height: "100vh",
      display: "flex",
      alignItems: "center",
      textAlign: "center",
      justifyContent: "center",
      position: "relative",
      overflow: "hidden"
    },
    parallax : {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      objectFit: "cover",
      zIndex: -1,
      transform: `translateY(${offset * 0.5}px)`
    }
  }

  useEffect(() => {
    function handleScroll() {
      setOffset(window.pageYOffset);
    }
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [offset])

  return(
    <section key={props.id} id={`#${props.id}`} style={style.hero} >
      <img
        src={background}
        alt={props.headline}
        style={style.parallax}
      />
      <div style={{textAlign: 'center', marginTop: '70px'}} className="container">
        <h2 className="sub-headline" style={{color: props.color}}>
          <span className="first-letter">{t('hero-sub-headline-firstletter')}</span>{t('hero-sub-headline')}
        </h2>
        <h1 className="headline">{props.headline}</h1>
        <div className="headline-description">
          <div className="separator">
            <div className="line line-left"></div>
            <div className="asterisk"><i className="fas fa-asterisk"></i></div>
            <div className="line line-right"></div>
          </div>
          <div className="single-animation">
            <h5>{props.regularOpening && isRestaurantOpen(props.regularOpening)}</h5>
          </div>
        </div>
      </div>
    </section>
  )
}

export default (HeroSection);
import { ENABLE_LOADING, DISABLE_LOADING } from "../Constants/action-types"

export function EnableLoading(){
  return function(dispatch){
    return dispatch({ type: ENABLE_LOADING, payload: 1 });
  }
}

export function DisableLoading(){
  return function(dispatch){
    return dispatch({ type: DISABLE_LOADING, payload: 0 });
  }
}
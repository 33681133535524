import React from 'react'
import { connect } from "react-redux"
import { Header, Separator, MenuSection } from '../components'

const RestaurantMenu = (props) => {

  return(
    <main>
      <Header
        disabled
      />
      <Separator 
        class='hero-small'
        anchor={'menu-now'}
      />
      <MenuSection
        restaurantMenuData={props.restaurantMenuData}
      />
    </main>
  )
}

const mapStateToProps = state => {
  return {
    restaurantMenuData: state.restaurantMenuData,
  }
}

export default connect(mapStateToProps)(RestaurantMenu)
import React from 'react'
import { useTranslation } from 'react-i18next'
import { InputField } from '../'
import AnimatedButton from '../buttonAnimated'

const ContactFormInput = (props) => {
  const {t} = useTranslation()
  return(
    <form>
      <div className="restaurant-info">
        <InputField 
          handleOnChange={props.handleOnChange}
          value={props.data.givenname}
          id="givenname"
          type="text"
          valueError={props.data.givennameError}
          label={t('input-label-givenname')}
          required
        />
        <InputField 
          handleOnChange={props.handleOnChange}
          value={props.data.surname}
          id="surname"
          type="text"
          valueError={props.data.surnameError}
          label={t('input-label-surname')}
          required
        />
        <InputField 
          handleOnChange={props.handleOnChange}
          value={props.data.mail}
          id="mail"
          type="mail"
          valueError={props.data.mailError}
          label={t('input-label-mail')}
          required
        />
      </div>
      <div className="restaurant-info">
        <InputField 
          handleOnChange={props.handleOnChange}
          value={props.data.address}
          id="address"
          type="text"
          label={t('input-label-streetAddress')}
        />
        <InputField 
          handleOnChange={props.handleOnChange}
          value={props.data.phone}
          id="phone"
          type="phone"
          valueError={props.data.phoneError}
          label={t('input-label-phone')}
          required
        />
      </div>
      <div className="restaurant-info">
        <InputField 
          handleOnChange={props.handleOnChange}
          value={props.data.message}
          id="message"
          type="textarea"
          valueError={props.data.messageError}
          label={t('input-label-message')}
          required
        />
      </div>
      <div className="restaurant-left">
        <div className="restaurant-left">
          <InputField 
            handleOnChange={props.handleOnChangeCheckbox}
            id="iAgree"
            type="checkbox"
            label={t('contact-form-iAgree')}
            required
          />
        </div>
      </div>
      <div className="restaurant-right">
        <div className="button-row">
          <AnimatedButton
            text={t('contact-form-submit')}
            onClick={props.handleOnSubmit}
          />
        </div>
      </div>
    </form>
  );
}

export default (ContactFormInput);
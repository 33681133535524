import React from 'react'

const Separator = (props) => {
  const style = {
    between : {
      minHeight: "65vh",
      display: "flex",
      alignItems: "center",
      backgroundSize: "cover",
      backgroundAttachment: "fixed",
      backgroundPosition: "bottom center"
    }
  }
  return(
    <section style={style.between} className={`${props.class} background-fixed`} key={props.id} id={`#${props.id}`} >
      <div className="container">
        <div className="global-headline">
            <h2 className="sub-headline" style={{color: props.color}}>
              <span className="first-letter">{props.subHeadlineFirstLetter}</span>{props.subHeadline}
            </h2>
            <h1 className="headline">{props.headline}</h1>
        </div>
      </div>
    </section>
  )
}

export default (Separator)
import React from "react"
import { Image, Modal, Header } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'

import useIosInstallPrompt from '../../hooks/useIosInstallPrompt';
import useWebInstallPrompt from '../../hooks/useWebInstallPrompt';

import image from "../../images/apple-touch-icon.png";
import ShareIcon from '../../assets/icons/IconAppleShare'

const InstallPWA = ({...props}) => {
    const {t} = useTranslation()
    const style = {
      header:{
        textAlign: "center",
        fontSize: "1.9rem",
        textTransform: "uppercase"
      }, 
      content: {
        textAlign: "center",
      },
      small: {
        textAlign: "center",
        fontSize: "1rem"
      },
      image: {
        transform: "translate(0, -50%)"
      },
      shareImg : {
        margin: "0px 5px 0px 5px",
        height: "10px",
        width: "10px"
      },
      btn: {
        textAlign: "center",
        textTransform: "uppercase",
        color: "rgba(0, 85, 179, 1)",
        cursor: "pointer"
      },
      appleShare: {
        color: "rgba(0, 85, 179, 1)",
        fill:"rgba(0, 85, 179, 1)",
        margin: "0px 5px 0px 5px",
        height: "15px",
        width: "15px"
      }
    }

    const [iosInstallPrompt, handleIOSInstallDeclined] = useIosInstallPrompt()
    const [webInstallPrompt, handleWebInstallDeclined, handleWebInstallAccepted] = useWebInstallPrompt()

    if (!iosInstallPrompt && !webInstallPrompt) {
      return null
    }

    return (
      <Modal
        open={true}
        size={'mini'}
      >
        <Modal.Content>
          <Modal.Description>
            <Image style={style.image} src={image} size='tiny' centered />
            <Header style={style.header} >{t('install-pwa-headline')}</Header>
            <p style={style.content} >{t('install-pwa-content')}</p>
            {iosInstallPrompt && (
              <div>
                <p style={style.small} > Drücke <ShareIcon modern={true} style={style.appleShare} /> und dann &quot;Zum Startbildschirm&quot;</p>
                <p style={style.btn} onClick={handleIOSInstallDeclined} >{t('button-close')}</p>

              </div>
            )}
            {webInstallPrompt && (
              <div>
                <p style={style.btn} >
                  <span onClick={handleWebInstallDeclined} >{t('button-close')}</span>{' '}
                  <span onClick={handleWebInstallAccepted} >{t('button-install')}</span>
                </p>
              </div>
            )}
          </Modal.Description>
        </Modal.Content>
      </Modal>
    )
}

export default InstallPWA
import React from 'react'
import { connect } from "react-redux";
import { GetRestaurantInfo, GetRestaurantOpeningTime, GetRestaurantActivitie } from "../redux/Actions/";
import { Image } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import { Container } from 'semantic-ui-react'
import { Footer, Header, Separator } from '../components'
import webdesign from '../images/webdesign.png'

const About = (props) => {
  const {t} = useTranslation()

  return (
    <main>
      <Header />
      <Separator 
        class='tasteful-hero'
        anchor={'our-about'}
      />
      <section className="our-about">
        <Container>
          <h1 className="headline headline-dark">{t('page-about-headline')}</h1>
          <h2 className="headline-dark">{t('page-about-headline-information')}</h2>
          <p>{props.restaurantInfo.name}</p>
          <p>{props.restaurantInfo.streetAddress}</p>
          <p>{props.restaurantInfo.postalCode} {props.restaurantInfo.locality}</p>
          <p><strong>{t('page-about-represented')}</strong></p>
          <p>{props.restaurantInfo.businessOrganisation}</p>
          <p>{t('page-about-business-manager')}: {props.restaurantInfo.businessManager}</p>
          <p>
            {props.restaurantInfo.localCourt}<br/>
            {props.restaurantInfo.localCourtNumber}
          </p>
          <h2 className="headline-dark">{t('page-about-contact-headline')}</h2>
          <p>{t('page-about-contact-phone')}: {props.restaurantInfo.phone}</p>
          <p>{t('page-about-contact-mail')}: {props.restaurantInfo.mail}</p>
          <h2 className="headline-dark">{t('page-about-tax-headline')}</h2>
          <p>{t('page-about-tax-content')}</p>
          <p>{props.restaurantInfo.ustId}</p>
          <h2 className="headline-dark">{t('page-about-dispute-headline')}</h2>
          <p>{t('page-about-dispute-content')}</p>
          <h2 className="headline-dark">{t('page-about-contentLiability-headline')}</h2>
          <p>{t('page-about-contentLiability-content')}</p>
          <h2 className="headline-dark">{t('page-about-linksLiability-headline')}</h2>
          <p>{t('page-about-linksLiability-content')}</p>
          <h2 className="headline-dark">{t('page-about-copyright-headline')}</h2>
          <p>{t('page-about-copyright-content')}</p>
          <h2 className="headline-dark">{t('page-about-design-headline')}</h2>
          <p>{t('page-about-design-content')}</p>
          <Image src={webdesign} size='large' alt="Dennis Rupp Webdesign" />
        </Container>
      </section>
      <Footer
        id="footer"
        handleOnUp={"/about/#our-about"}
        restaurantOpeningTime={props.restaurantOpeningTime}
        restaurantInfo={props.restaurantInfo}
      />
    </main>
  );
}

const mapStateToProps = state => {
  return {
    restaurantOpeningTime: state.restaurantOpeningTime,
    restaurantActivitie: state.restaurantActivitie,
    restaurantInfo: state.restaurantInfo
  };
};

export default connect(mapStateToProps,{GetRestaurantInfo, GetRestaurantOpeningTime, GetRestaurantActivitie})(About)
import { SW_INIT, SW_UPDATE, GET_RESTAURANT_MENU_DATA, GET_RESTAURANT_MENU_OFFER, GET_RESTAURANT_OPENING_TIME, GET_RESTAURANT_ACTIVITE, TOGGLE_SIDEBAR, GET_RESTAURANT_INFO } from "../Constants/action-types";
import { ENABLE_LOADING, DISABLE_LOADING } from "../Constants/action-types"
const initialState = {
  loading: 0,
  toggleSidebar: false,
  isMobile: true,
  restaurantMenuData: [],
  restaurantMenuOffer: [],
  restaurantOpeningTime: [],
  restaurantActivitie: [],
  restaurantInfo: {},
  restaurantStorys: [],
};

function rootReducer(state = initialState, action) {

  if (action.type === SW_INIT) {
    console.log(action)
  } 

  if (action.type === SW_UPDATE) {
    console.log(action)
  }

  if (action.type === ENABLE_LOADING) {
    return Object.assign({},state, {
      loading: action.payload
    })
  } 

  if (action.type === DISABLE_LOADING) {
    return Object.assign({},state, {
      loading: action.payload
    })
  } 

  if (action.type === TOGGLE_SIDEBAR) {
    if(action.payload)
      return Object.assign({},state, {
        toggleSidebar: (action.payload)
      });
    else
      return Object.assign({},state, {
        toggleSidebar: (!state.toggleSidebar)
      });
  }

  if (action.type === GET_RESTAURANT_INFO) {
    return Object.assign({},state, {
      restaurantInfo: action.payload
    });
  }

  if (action.type === GET_RESTAURANT_MENU_DATA) {
    return Object.assign({},state, {
      restaurantMenuData: action.payload
    });
  }

  if (action.type === GET_RESTAURANT_MENU_OFFER) {
    return Object.assign({},state, {
      restaurantMenuOffer: action.payload
    });
  }

  if (action.type === GET_RESTAURANT_OPENING_TIME) {
    return Object.assign({},state, {
      restaurantOpeningTime: action.payload
    });
  }

  if (action.type === GET_RESTAURANT_ACTIVITE) {
    return Object.assign({},state, {
      restaurantActivitie: action.payload
    });
  }

  return state;
}

export default rootReducer;
import axios from 'axios';
import { Buffer } from 'buffer';

const auth = new Buffer.from(window._env_.REACT_APP_API_USER + ":" + window._env_.REACT_APP_API_PASSWORD).toString("base64")

axios.interceptors.request.use(
  (config) => {
    config.headers["Authorization"] = 'Basic ' + auth;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export const subscribeNewsletter = async(data) => {
  var options = {
    'method': 'POST',
    'url': `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/locations/${window._env_.REACT_APP_PRIMARY_LOCATION_CODE}/newsletters`,
    'headers': {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify({"givenname":data.givenname,"surname":data.surname,"mail":data.mail})
  }
  axios.request(options)
    .then((response) => {
      return true
    })
    .catch((error) => {
      return false
    });
}

export const submitContactMessage = (data) => {

  var options = {
    'method': 'POST',
    'url': `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/locations/${window._env_.REACT_APP_PRIMARY_LOCATION_CODE}/contacts`,
    'headers': {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify({
      "givenname":data.givenname,
      "surname":data.surname,
      "mail":data.mail,
      "phone": data.phone,
      "streetAddress": data.streetAddress,
      "postalCode": data.postalCode,
      "location": data.location,
      "message": data.message
    })
  }
  axios.request(options)
    .then((response) => {
      return true
    })
    .catch((error) => {
      return false
    });
}

export const verifyNewsletter = (newsletterId) => {

  var options = {
    'method': 'PATCH',
    'url': `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/locations/${window._env_.REACT_APP_PRIMARY_LOCATION_CODE}/newsletters/${newsletterId}`,
    'headers': {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify({
      "state": "subscribed"
    })
  }
  axios.request(options)
    .then((response) => {
      return true
    })
    .catch((error) => {
      return false
    });
}

export const unsubscribeNewsletter = (newsletterId) => {

  var options = {
    'method': 'DELETE',
    'url': `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/locations/${window._env_.REACT_APP_PRIMARY_LOCATION_CODE}/newsletters/${newsletterId}`,
  }
  axios.request(options)
    .then((response) => {
      return true
    })
    .catch((error) => {
      return false
    });
}

export const createReservation = async(data) => {
  var options = {
    'method': 'POST',
    'url': `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/locations/${window._env_.REACT_APP_PRIMARY_LOCATION_CODE}/reservations`,
    'headers': {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(data)
  }
  axios.request(options)
    .then((response) => {
      return true
    })
    .catch((error) => {
      return false
    });
}

export const updateReservation = async (reservationId, state) => {
  try {
    var options = {
      'method': 'PATCH',
      'url': `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/locations/${window._env_.REACT_APP_PRIMARY_LOCATION_CODE}/reservations/${reservationId}/${state}`
    }
    var result = await axios(options)
    return result.data
  }
  catch (err) {
    console.log(err)
  }
}

export const getReservation = async (reservationId) => {

  var options = {
    'method': 'GET',
    'url': `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/locations/${window._env_.REACT_APP_PRIMARY_LOCATION_CODE}/reservations/${reservationId}`
  }
  var result = await axios(options)
  return result.data
}
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from "react-router-dom";
import { Grid, Segment, Container, Menu, Icon } from 'semantic-ui-react'
import MenuItem from './menuItem'
import MenuCategorie from './menuCategorie'
import MenuAllergenFilter from './menuAllergenFilter'

import './menuSection.scss'
import AnimatedButton from '../buttonAnimated';

const MenuSection = (props) => {
  const {t} = useTranslation()
  const navigate = useNavigate()

  const style = {
    btn: {
      opacity: 0.7,
      padding: '2em',
      display: "contents",
      "fontSize": "1.4rem"
    },
    btnActive: {
      opacity: 0.7,
      padding: '2em',
      display: "contents",
      "fontSize": "1.4rem",
      "color": "green"
    },
    center: {
      textAlign: "center"
    }
  }
  const [ menuFilter, setMenuFilter ] = useState('lunch')
  const [ allergenFilter, setAllergenFilter ] = useState([])
  const [ showAllergenFilter, setShowAllergenFilter ] = useState(false)

  const handleOnClickLink = (link) => {
    navigate(`/${link}`)
  }

  const handleOnEditAllergenFilter = (e) => {
    var arr = []
    var value = e.target.value
    var checked = e.target.checked
    if(checked === true) {
      arr = allergenFilter
      arr.push(value)
    } 
    else {
      arr = allergenFilter.filter(item => item !== value)
    }
    setAllergenFilter(arr)
  }

  const restaurantMenu = (filter, allergen) => {
    var new_obj_array = props.restaurantMenuData.filter(function(obj) {
      if(obj.allergens){
        var isFounded = obj.allergens.split(';').some(v=> allergen.indexOf(v) !== -1)
      }
      if(filter.indexOf(obj.section) === -1 || isFounded)
        return false;
      else
        return true;
    })
    new_obj_array.sort((a, b) => a.category.localeCompare(b.category))
    var categories = new_obj_array.map( (value) => value.category).filter( (value, index, _arr) => _arr.indexOf(value) === index);
    var leftArea = []
    var rightArea = []

    categories.forEach((element, index) => {
      var items = []
      items.push(
        <MenuCategorie 
          name={element} 
          id={`cat_${element}`}
          key={`cat_${element}`}
        />
      )
      var objects = new_obj_array.filter((object) => { return object.category === element })
      objects.forEach(element => {
        items.push(
          <MenuItem
            id={`item_${element.foodMenuId}`}
            key={`item_${element.foodMenuId}`}
            data={element} 
          />
        )
      });
      if(leftArea <= rightArea)
        leftArea.push(
          <Segment key={`segment_menu_left_${index}`} vertical style={{border:'none'}}>
            {items}
          </Segment>
        )
      else
        rightArea.push(
          <Segment key={`segment_menu_right_${index}`} vertical style={{border:'none'}}>
            {items}
          </Segment>
        )
    });
    if(categories.length > 1){
      return(<>
        <Grid stackable columns={2}>
          <Grid.Row padded>
            <Grid.Column>
              {leftArea}
            </Grid.Column>
            <Grid.Column>
              {rightArea}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </>)
    }
    else
      return (
        <Grid columns={1}>
          <Grid.Row padded>
            <Grid.Column>
              {leftArea}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )
  }

  return(
    <section key={props.id} id={`#${props.id}`} className="discover-our-story">
      <Container>
        <div className={'mb-40'}>
          <div className={'text-center scroll-enabled'}>
            <div>
              <Menu text fluid compact >
                {props.restaurantMenuData && props.restaurantMenuData.find(o => o.section === 'breakfast') && 
                <Menu.Item 
                  key={'menu-nav-breakfast'} 
                  name={t(`menu-nav-breakfast`)}
                  onClick={()=>setMenuFilter('breakfast')}
                  className={'text-body'}
                  active={menuFilter==='breakfast'}
                />
                }
                {props.restaurantMenuData && props.restaurantMenuData.find(o => o.section === 'sunday') && 
                <Menu.Item 
                  key={'menu-nav-sunday'} 
                  name={t(`menu-nav-sunday`)}
                  onClick={()=>setMenuFilter('sunday')}
                  className={'text-body'}
                  active={menuFilter==='sunday'}
                />
                }
                {props.restaurantMenuData && props.restaurantMenuData.find(o => o.section === 'appetizer') && 
                <Menu.Item 
                  key={'menu-nav-appetizer'} 
                  name={t(`menu-nav-appetizer`)}
                  onClick={()=>setMenuFilter('appetizer')}
                  className={'text-body'}
                  active={menuFilter==='appetizer'}
                />
                }
                {props.restaurantMenuData && props.restaurantMenuData.find(o => o.section === 'lunch') && 
                <Menu.Item 
                  key={'menu-nav-lunch'} 
                  name={t(`menu-nav-lunch`)}
                  onClick={()=>setMenuFilter('lunch')}
                  className={'text-body'}
                  active={menuFilter==='lunch'}
                />
                }
                {props.restaurantMenuData && props.restaurantMenuData.find(o => o.section === 'desert') && 
                <Menu.Item 
                  key={'menu-nav-desert'} 
                  name={t(`menu-nav-desert`)}
                  onClick={()=>setMenuFilter('desert')}
                  className={'text-body'}
                  active={menuFilter==='desert'}
                />
                }
                {props.restaurantMenuData && props.restaurantMenuData.find(o => o.section === 'beverages') && 
                <Menu.Item 
                  key={'menu-nav-beverages'} 
                  name={t(`menu-nav-beverages`)}
                  onClick={()=>setMenuFilter('beverages')}
                  className={'text-body'}
                  active={menuFilter==='beverages'}
                />
                }
                {props.restaurantMenuData && props.restaurantMenuData.find(o => o.section === 'wines') && 
                <Menu.Item 
                  key={'menu-nav-wines'} 
                  name={t(`menu-nav-wines`)}
                  onClick={()=>setMenuFilter('wines')}
                  className={'text-body'}
                  active={menuFilter==='wines'}
                />
                }
              </Menu>
            </div>
          </div>
          <div className={'text-center'}>
            <div>
              <button className="button" style={allergenFilter.length > 0 ? style.btnActive : style.btn} onClick={()=>setShowAllergenFilter(true)} >
                {t('navigate-to-allergen')}
                <Icon name='search' />
              </button>
            </div>
          </div>
        </div>
          { props.restaurantMenuData && (restaurantMenu(menuFilter, allergenFilter)) }
        <div className="restaurant-right mt-40">
          <div className="button-row">
            <AnimatedButton
              text={t('navigate-to-special')}
              onClick={()=>handleOnClickLink('#offers')}
            />
          </div>
        </div>
      </Container>
      <MenuAllergenFilter
        open={showAllergenFilter}
        setOpen={setShowAllergenFilter}
        restaurantMenuData={props.restaurantMenuData}
        handleOnEdit={handleOnEditAllergenFilter}
        values={allergenFilter}
      />
    </section>
  );
}

export default MenuSection;
import React from 'react'
import SocialIcon from './socialIcon'

const SocialMedia = (props) => {
  return(
    <div className="social-media">
        <ul className="block-social mb-40">
          {props.facebook && (<SocialIcon 
            key='sozial-facebook'
            size='large'
            link={props.facebook}
            icon='facebook f'
          />)}
          {props.twitter && (<SocialIcon 
            size='large'
            key='sozial-twitter'
            link={props.twitter}
            icon='twitter'
          />)}
          {props.instagram && (<SocialIcon 
            size='large'
            key='sozial-instagram'
            link={props.instagram}
            icon='instagram'
          />)}
          {props.youtube && (<SocialIcon 
            size='large'
            key='sozial-youtube'
            link={props.youtube}
            icon='youtube'
          />)}
        </ul>
    </div>
  )
}

export default SocialMedia
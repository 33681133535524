import React from 'react'
import { connect } from "react-redux";
import LoadingImage from './images/logo-animation-small.webp'
import "./loader.scss"

const Loader = props => {
  return(
    <>{props.loading > 0 && (
      <div className="loaderCover">
        <img className='laoderSpinner' src={LoadingImage} alt='Schlemmerwerk Loading Animation'/>
      </div>
    )}</>
  )
}

const mapStateToProps = state => {
  return {
    loading: state.loading
  };
};

export default connect(mapStateToProps)(Loader);
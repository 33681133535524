export const getDateFirstDayOfThisWeek = function() {
  var curr = new Date()
  var first = curr.getDate() - curr.getDay()
  var firstday = new Date(curr.setDate(first)).toUTCString()
  return firstday
}

export const getDateLastDayOfThisWeek = function() {
  var curr = new Date()
  var first = curr.getDate() - curr.getDay()
  var last = first + 6
  var lastday = new Date(curr.setDate(last)).toUTCString();
  return lastday
}

function _getMinutes(str) {
  var time = str.split(':');
  return time[0]*60+time[1]*1;
}

function _getMinutesNow() {
  var timeNow = new Date();
  return timeNow.getHours()*60+timeNow.getMinutes();
}

function _getMinutesAtDateTime(timeNow) {
  return timeNow.getHours()*60+timeNow.getMinutes();
}

export const isRestaurantOpen = function(regularOpening) {
  var weekday = new Array(7);
  weekday["SUNDAY"] = 0;
  weekday["MONDAY"] = 1;
  weekday["TUESDAY"] = 2;
  weekday["WEDNESDAY"] = 3;
  weekday["THURSDAY"] = 4;
  weekday["FRIDAY"] = 5;
  weekday["SATURDAY"] = 6;

  var curr = new Date().getDay()
  var matchedIndex = null
  var matchedDay = null
  regularOpening.forEach((element, index) => {
    let dayOpen = weekday[element.openDay]
    let dayClose = weekday[element.closeDay]

    if(dayOpen === 0)
      dayOpen = 7
    if(dayClose === 0)
      dayClose = 7
    if(curr === 0)
      curr = 7
    if((curr >= dayOpen) && (curr <= dayClose)){
      matchedDay = index
      var now = _getMinutesNow()
      var start = _getMinutes(element.openTime)
      var end = _getMinutes(element.closeTime)
      if (start > end) 
        end += _getMinutes('24:00')

      if ((now >= start) && (now <= end)){
        matchedIndex = index
      }
    }
  })

  if(matchedIndex !== null)
    return (`Wir sind jetzt von ${regularOpening[matchedIndex].openTime}UHR - ${regularOpening[matchedIndex].closeTime}UHR da.`)
  else if (matchedDay !== null)
    return (`Wir haben jetzt Geschlossen`)
  else
    return (`Geschlossen`)
}

export const isRestaurantOpenNow = function(regularOpening) {
  var weekday = new Array(7);
  weekday["SUNDAY"] = 0;
  weekday["MONDAY"] = 1;
  weekday["TUESDAY"] = 2;
  weekday["WEDNESDAY"] = 3;
  weekday["THURSDAY"] = 4;
  weekday["FRIDAY"] = 5;
  weekday["SATURDAY"] = 6;

  var curr = new Date().getDay()
  var matchedIndex = null
  regularOpening.forEach((element, index) => {
    let dayOpen = weekday[element.openDay]
    let dayClose = weekday[element.closeDay]

    if(dayOpen === 0)
      dayOpen = 7
    if(dayClose === 0)
      dayClose = 7
    if(curr === 0)
      curr = 7
    if((curr >= dayOpen) && (curr <= dayClose)){
      var now = _getMinutesNow()
      var start = _getMinutes(element.openTime)
      var end = _getMinutes(element.closeTime)
      if (start > end) 
        end += _getMinutes('24:00')

      if ((now >= start) && (now <= end)){
        matchedIndex = index
      }
    }
  })

  
  if(matchedIndex !== null)
    return true
  else
    return false
}

export const isRestaurantOpenAtDateTime = function(regularOpening, date, time) {
  var weekday = new Array(7);
  weekday["SUNDAY"] = 0;
  weekday["MONDAY"] = 1;
  weekday["TUESDAY"] = 2;
  weekday["WEDNESDAY"] = 3;
  weekday["THURSDAY"] = 4;
  weekday["FRIDAY"] = 5;
  weekday["SATURDAY"] = 6;

  var dateTime = new Date(`${date} ${time}`)
  var curr = dateTime.getDay()
  var matchedIndex = null
  regularOpening.forEach((element, index) => {
    let dayOpen = weekday[element.openDay]
    let dayClose = weekday[element.closeDay]

    if(dayOpen === 0)
      dayOpen = 7
    if(dayClose === 0)
      dayClose = 7
    if(curr === 0)
      curr = 7
    if((curr >= dayOpen) && (curr <= dayClose)){
      var now = _getMinutesAtDateTime(dateTime)
      var start = _getMinutes(element.openTime)
      var end = _getMinutes(element.closeTime)
      if (start > end) 
        end += _getMinutes('24:00')

      if ((now >= start) && (now <= end)){
        matchedIndex = index
      }
    }
  })

  
  if(matchedIndex !== null)
    return true
  else
    return false
}
import useShouldShowPrompt from './useShouldShowPrompt'
const iosInstallPromptedAt = 'iosInstallPromptedAt'

const isIOS = () => {
  if (navigator.standalone) {
    return false
  }
  const ua = window.navigator.userAgent
  const isIPad = !!ua.match(/iPad/i)
  const isIPhone = !!ua.match(/iPhone/i)
  const isIOS = isIPad || isIPhone
  const webkit = !!ua.match(/WebKit/i)
  const isSafari = isIOS && webkit && !ua.match(/CriOS/i)

  return isIPhone || isIPad || isIOS || isSafari
}

const useIosInstallPrompt = () => {
  const [userShouldBePromptedToInstall, handleUserSeeingInstallPrompt] = useShouldShowPrompt(iosInstallPromptedAt)
  return [isIOS() && userShouldBePromptedToInstall, handleUserSeeingInstallPrompt]
}
export default useIosInstallPrompt
import React from 'react'
import { useNavigate } from "react-router-dom"
import { useTranslation } from 'react-i18next'
import { Grid, Icon } from 'semantic-ui-react'
import { Map, SocialMedia } from '../'

import './footer.scss'

const Footer = (props) => {
  const {t} = useTranslation()
  const navigate = useNavigate()

  const handleOnClickLink = (link) => {
    navigate(`/${link}`)
  }

  const renderArrayValue = (value, index) => {
    var items = []
    value.forEach(element => {
      items.push(
        <>
          <Icon.Group key={`opening_time_${index}`}>
            <Icon color='white' name='clock outline'/>{element}<br/>
          </Icon.Group><br/>
        </>
      )
    });
    return items
  }

  const renderOpening = () => {
    var items = []
    var arrayObj = []
    props.restaurantOpeningTime.forEach(element => {
      var dayString = ''
      var timeString = `${element.openTime} - ${element.closeTime}`
      if(element.openDay !== element.closeDay)
        dayString = t(element.openDay) + " - " + t(element.closeDay)
      else
        dayString = t(element.openDay)

      var index = arrayObj.findIndex(x => x.name===dayString)
      if(index === -1)
        arrayObj.push({name:dayString, value:[timeString]})
      else
        arrayObj[index].value.push(timeString)
    })
    arrayObj.forEach((element,index) => {
      items.push(<div style={{marginBottom: 25}} key={`opening_day_${index}`}>
        <h2>{element.name}</h2>
        {renderArrayValue(element.value, index)}</div>
      )
    });
    return items
  }

  return(
    <footer key={props.id} id={`#${props.id}`}>
      <div className="back-to-top">
        <a href={props.handleOnUp}><i className="fas fa-chevron-up"></i></a>
      </div>
      <div className="footer-content">
        <Grid stackable columns={2} className={'mb-40'}>
          <Grid.Column>
            <h4 style={{textTransform: "uppercase"}}>{t('footer-content-location-headline')}</h4>
            <SocialMedia
              twitter={props.restaurantInfo.linkTwitter}
              facebook={props.restaurantInfo.linkFacebook}
              instagram={props.restaurantInfo.linkInstagram}
              youtube={props.restaurantInfo.linkYouTube}
            />
            <div>
              <Icon.Group size='large' className={'mb-10'}>
                <Icon color='white' name='map marker'/>{props.restaurantInfo.streetAddress}<br/>{props.restaurantInfo.postalCode}{' '}{props.restaurantInfo.locality}
              </Icon.Group><br/>
              { props.restaurantInfo.phone && <Icon.Group size='large' className={'mb-10 click-enable'} onClick={(e) => { window.location = `tel:${props.restaurantInfo.mail}`; e.preventDefault();}}>
                <Icon color='white' name='phone'/>{props.restaurantInfo.phone}
              </Icon.Group>}
            </div>
          </Grid.Column>
          <Grid.Column>
            <h4 key="opening_day_headline" style={{textTransform: "uppercase"}}>{t('footer-content-opening-headline')}</h4>
            {props.restaurantOpeningTime && renderOpening()}
          </Grid.Column>
        </Grid>
        <Grid stackable columns={1}>
          <Grid.Column>
            <Map />
          </Grid.Column>
        </Grid>
        <Grid stackable columns={1}> 
          <Grid.Column>
            <Grid stackable columns={1}>
              <Grid.Column><p><span onClick={()=>handleOnClickLink('about')} className={'footer-links'}>{t('footer-content-link-about')}</span> <span onClick={()=>handleOnClickLink('policy')} className={'footer-links'}>{t('footer-content-link-policy')}</span></p></Grid.Column>
            </Grid>
          </Grid.Column>
        </Grid>
      </div>
    </footer>
  );
}

export default Footer
import React from 'react'
import { connect } from "react-redux";
import { ToggleSidebar } from "../../redux/Actions/";

import './hamburgerToggle.scss'

const HamburgerToggle = (props) => {
  const handleOnToggle = () => {
    props.ToggleSidebar()
  }

  return(
    <div className={'toggle-btn'}>
      <button onClick={handleOnToggle} className={props.toggleSidebar ? `dots on` : 'dots'}><span></span></button>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    toggleSidebar: state.toggleSidebar,
  }
}

export default connect(mapStateToProps,{ToggleSidebar})(HamburgerToggle);
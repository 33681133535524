import React from 'react'

import './menuSection.scss'

const MenuCategorie= (props) => {
  return(
    <div id={props.id} className="block-cat">
      <h2 className="mb-30">{props.name}</h2>
    </div>
  )
}

export default MenuCategorie;
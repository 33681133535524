import React, { useState, useLayoutEffect } from 'react'
import { connect } from "react-redux";
import { ToggleSidebar } from "../../redux/Actions/";
import { Menu, Sidebar, Image, Button, Icon } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import settings from '../../config/Settings.json'
import { isRestaurantOpenNow } from '../../utils/dateHelper'
import { Link } from "react-router-dom";
import { HamburgerToggle } from '../'

import Logo from '../../images/logo-white.png'
import './header.scss'

const Header = (props) => {
  const {t} = useTranslation()

  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize(window.innerWidth);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  const renderMenu = () => {
    var items = []
    settings.headerMenu.sort((a,b) => a.position - b.position);
    settings.headerMenu.forEach(element => {
      items.push(<Menu.Item
        key={element.name}
        name={t(`header-menu-${element.name}`)}
        as={Link}
        to={`/#${element.section}`}
        onClick={()=>props.ToggleSidebar(false)}
        className={'text-white'}
      >
      </Menu.Item>)
    });
    return items
  }

  return(
    <header>
      <Image src={Logo} size='large' centered alt="header logo"/>
      {!props.disabled ? size < 950 ? 
        <div>
          <Sidebar
              as={Menu}
              animation='slide along'
              icon='labeled'
              inverted
              vertical
              visible={props.toggleSidebar}
              width='wide'
            >
            {renderMenu()}
            {isRestaurantOpenNow(props.restaurantOpeningTime) && <div className='mt-40'>
              <Button basic inverted color='green' onClick={(e) => {
                window.location = `tel:${props.restaurantInfo.phone}`;
                e.preventDefault();
              }}>
                <Icon name='call' />{t(`header-menu-call`)}
              </Button>
            </div>}
          </Sidebar>
          <HamburgerToggle />
        </div> :
        <Menu style={{justifyContent: 'center'}} text inverted pointing>
          {renderMenu()}
        </Menu> : ''
      }
    </header>
  );
}

const mapStateToProps = state => {
  return {
    toggleSidebar: state.toggleSidebar,
    restaurantInfo: state.restaurantInfo,
    restaurantOpeningTime: state.restaurantOpeningTime
  };
};

export default connect(mapStateToProps,{ToggleSidebar})((Header));
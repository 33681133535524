import React from 'react'
import { Container, Grid } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import image from '../../images/cooking.jpg'

const MainSection = () => {
  const {t} = useTranslation()

  return(
    <>
    <section className="discover-our-story">
      <div className="container">
        <div className="restaurant-info">
          <div className="restaurant-description padding-right">
            <div className="global-headline">
              <h2 className="sub-headline">
                <span className="first-letter">{t('about-sub-headline-firstletter')}</span>{t('about-sub-headline')}
              </h2>
              <h1 className="headline headline-dark">{t('about-headline')}</h1>
            </div>
            <p>{t('about-content')}</p>
          </div>
          <div className="restaurant-info-img">
            <img src={image} alt=""></img>
          </div>
        </div>
      </div>
    </section>
    <section style={{paddingTop: "0px", paddingLeft: "4rem", paddingRight: "4rem"}} key={'text-section-kitchen'} id={`#text-section-kitchen`}>
      <Container>
        <Grid stackable>
          <Grid.Column width={10} textAlign='right' verticalAlign='middle'>
            <p>{t('info-area-02-section-content')}</p>
          </Grid.Column>
          <Grid.Column width={6}>        
            <div className="restaurant-description padding-right">
              <div className="global-headline">
                <h2 className="sub-headline">
                  <span className="first-letter">{t('info-area-02-section-sub-headline-firstletter')}</span>{t('info-area-02-section-sub-headline')}
                </h2>
                <h1 className="headline headline-dark">{t('info-area-02-section-headline')}</h1>
              </div>
            </div>
          </Grid.Column>
        </Grid>
      </Container>
    </section>
    <section style={{paddingTop: "0px", paddingLeft: "4rem", paddingRight: "4rem"}} key={'text-section-event'} id={`#text-section-event`}>
      <Container>
        <Grid stackable>
          <Grid.Column width={6}>        
            <div className="restaurant-description padding-right">
              <div className="global-headline">
                <h2 className="sub-headline">
                  <span className="first-letter">{t('info-area-03-section-sub-headline-firstletter')}</span>{t('info-area-03-section-sub-headline')}
                </h2>
                <h1 className="headline headline-dark">{t('info-area-03-section-headline')}</h1>
              </div>
            </div>
          </Grid.Column>
          <Grid.Column width={10} textAlign='left' verticalAlign='middle'>
            <p>{t('info-area-03-section-content')}</p>
            <p>{t('info-area-03-section-content-02')}</p>
          </Grid.Column>
        </Grid>
      </Container>
    </section>
    <section style={{paddingTop: "0px", paddingLeft: "4rem", paddingRight: "4rem"}} key={'text-section-garden'} id={`#text-section-garden`}>
      <Container>
        <Grid stackable>
          <Grid.Column width={10} textAlign='right' verticalAlign='middle'>
            <p>{t('info-area-01-section-content')}</p>
          </Grid.Column>
          <Grid.Column width={6}>        
            <div className="restaurant-description padding-right">
              <div className="global-headline">
                <h2 className="sub-headline">
                  <span className="first-letter">{t('info-area-01-section-sub-headline-firstletter')}</span>{t('info-area-01-section-sub-headline')}
                </h2>
                <h1 className="headline headline-dark">{t('info-area-01-section-headline')}</h1>
              </div>
            </div>
          </Grid.Column>
        </Grid>
      </Container>
    </section>
    </>
  );
}

export default MainSection
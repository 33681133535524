import React from 'react'
import { useTranslation } from 'react-i18next'
import { Popup } from 'semantic-ui-react'

import './menuSection.scss'
import vegan from '../../assets/icons/vegan.svg'
import vegetarian from '../../assets/icons/vegetarian.svg'

const MenuItem= (props) => {
  const {t} = useTranslation()
  const style = {
    popUp: {
      borderRadius: 0,
      opacity: 0.7,
      padding: '2em'
    },
    additives : {

    }
  }

  return(
    <div id={props.id} className="block-content  pb-25 mb-25">
      <h2 className="mb-5 block-header">{props.data.name}</h2>
      <p>{props.data.description}</p>
      {props.data.vegan === 1 && (<Popup style={style.popUp} inverted content={t('menu-item-vegan')} trigger={<img className="food-image" src={vegan} alt="vegan"/>} />)}
      {props.data.vegetarian === 1 && (<Popup style={style.popUp} inverted content={t('menu-item-vegetarian')} trigger={<img className="food-image" src={vegetarian} alt="vegetarian"/>} />)}
      <span className="block-price ">{props.data.size && (props.data.size + " | ")}€{props.data.price}</span>
      {props.data.additives && props.data.additives.split(';').map((element,index,array)=>(
        <small key={`additives-filter-${element}`} >{index === 0 && <>( </>}{t(`additives-filter-${element}`)}{index === (array.length -1)? <> )</> : <>, </>}</small>
      ))}
      <span className="dots"></span>
    </div>
  )
}

export default MenuItem;
import React from 'react'
import { Icon } from 'semantic-ui-react'

const SocialIcon = (props) => {

  return(
      <Icon.Group size={props.size} onClick={()=> window.open(props.link, '_blank')} >
        <Icon circular inverted name={props.icon} link />
      </Icon.Group> 
  )
}

export default SocialIcon
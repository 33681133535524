import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Header, Icon, Modal, Checkbox, Grid } from 'semantic-ui-react'

import './menuSection.scss'


const MenuAllergenFilter= (props) => {
  const {t} = useTranslation()

  const style = {
    chk: {
      "fontSize": "1.8rem",
      "padding": "10px"
    }
  }
  const renderArray = () => {
    const array = [...new Set(props.restaurantMenuData.map(item => item.allergens && item.allergens.split(';')))]
    let unique = [...new Set(array.flat(1))]
    var items = []
    unique.forEach(element => {
      if(element)
        items.push(
          <Grid.Column key={`allergen-filter-item-${element}`} mobile={16} tablet={8} computer={8} >
            <Checkbox toggle label={t(`allergen-filter-${element}`)} value={element} style={style.chk} id={element} onChange={props.handleOnEdit} defaultChecked={props.values.includes(element)}/>
          </Grid.Column>
        )
    })
    return items
  }

  return(
    <Modal
      basic
      onClose={() => props.setOpen(false)}
      onOpen={() => props.setOpen(true)}
      open={props.open}
      size='small'
    >
      <Header icon>
        <Icon name='food' />
        {t('navigate-to-allergen')}
      </Header>
      <Modal.Content>
        <p>
          {t('allergen-filter-content')}
        </p>
        <Grid columns='two' divided>
          <Grid.Row>
            {props.restaurantMenuData && renderArray()}
          </Grid.Row>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button color='green' inverted onClick={() => props.setOpen(false)}>
          <Icon name='checkmark' /> {t('button-accept')}
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default MenuAllergenFilter;
import React from 'react'
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next'
import { Grid, Container } from 'semantic-ui-react'
import OfferItem from './offerItem'
import AnimatedButton from '../buttonAnimated'
import './offerSection.scss'

const OfferSection = (props) => {
  const {t} = useTranslation()
  const navigate = useNavigate()

  const handleOnClickLink = (link) => {
    navigate(`/${link}`)
  }

  const restaurantOffer = () => {
    var items = []
    var count = 0
    props.restaurantMenuOffer.forEach((element, index) => {
      var styleIndex = (count % 2)
      items.push(
        <OfferItem 
          name={element.name}
          description={element.description}
          price={element.price}
          backgroundImage={element.image}
          key={element.localOfferId}
          localOfferId={element.localOfferId}
          currency={element.currency}
          size={element.size}
          hasImage={element.hasImage}
          sp={styleIndex}
        />
      )
      count++
      if(count===3)
        count = 0
    });
    return items
  }

  return(
    <section key={props.id} id={`#${props.id}`} className="restaurant-offers">
      <Container>
        <Grid stackable centered columns={3}>
          {props.restaurantMenuOffer.length > 0 ? restaurantOffer(): 
            <div className="global-headline">
              <h2 className="sub-headline">
                <span className="first-letter">{t('offer-sub-headline-firstletter')}</span>{t('offer-sub-headline')}
              </h2>
              <h1 className="headline headline-dark">{t('offer-headline')}</h1>
              <div className="asterisk"><i className="fas fa-asterisk"></i></div>
            </div>
          }
        </Grid>
        <div className="restaurant-right mt-40">
          <div className="button-row">
            <AnimatedButton
              text={t('navigate-to-menu')}
              onClick={()=>handleOnClickLink('#menu')}
            />
          </div>
        </div>
      </Container>
    </section>
  );
}

export default OfferSection;
import React from 'react'
import { Transition, Header, Modal, Image } from 'semantic-ui-react'
import { InputField } from '../'
import { useTranslation } from 'react-i18next'
import image from "../../images/apple-touch-icon.png";
import './style.scss'

const ReservationPush = (props) => {
  const {t} = useTranslation()
  const style = {
    header:{
      textAlign: "center",
      fontSize: "1.9rem",
      textTransform: "uppercase"
    }, 
    content: {
      textAlign: "center",
    },
    small: {
      textAlign: "center",
      fontSize: "1rem"
    },
    image: {
      transform: "translate(0, -50%)"
    },
    shareImg : {
      margin: "0px 5px 0px 5px",
      height: "10px",
      width: "10px"
    },
    btn: {
      textAlign: "center",
      textTransform: "uppercase",
      color: "rgba(0, 85, 179, 1)",
      cursor: "pointer"
    },
    appleShare: {
      color: "rgba(0, 85, 179, 1)",
      fill:"rgba(0, 85, 179, 1)",
      margin: "0px 5px 0px 5px",
      height: "15px",
      width: "15px"
    }
  }

  return(
    <Transition.Group animation={'fly down'} duration={1500}>
      <Modal
        size={'small'}
        open={props.open}
        onClose={props.onClickCancel}
      >
      <Modal.Content>
        <Modal.Description>
          <Image style={style.image} src={image} size='tiny' centered />
          <Header style={style.header} >{t('push-reservation-header')}</Header>
          {props.step === 1 && tableBooking(props, t)}
          {props.step === 2 && dataBooking(props, t)}
          {props.step === 3 && finishBooking(style, t)}
          {props.step === 4 && errorBooking(style, t)}
          <div>
            <p style={style.btn} >
              <span style={{margin: 15}} onClick={props.onClickCancel} >{t('button-cancel')}</span>
              <span style={{margin: 15}} onClick={props.onClickNext} >{t('button-next')}</span>
            </p>
          </div>
        </Modal.Description>
      </Modal.Content>
      </Modal>
    </Transition.Group>
  )
}

export default ReservationPush 

function finishBooking(style, t) {
  return <p style={style.content}>{t('push-reservation-finish')}</p>;
}

function errorBooking(style, t) {
  return <p style={style.content}>{t('push-reservation-error')}</p>;
}

function dataBooking(props, t) {
  return <form>
    <div className="restaurant-info">
      <InputField
        handleOnChange={props.handleOnChange}
        value={props.givenname}
        id="givenname"
        type="text"
        valueError={props.givennameError}
        label={t('input-label-givenname')}
        required />
      <InputField
        handleOnChange={props.handleOnChange}
        value={props.surname}
        id="surname"
        type="text"
        valueError={props.surnameError}
        label={t('input-label-surname')}
        required />
    </div>
    <div className="restaurant-info">
      <InputField
        handleOnChange={props.handleOnChange}
        value={props.phone}
        id="phone"
        type="tel"
        valueError={props.phoneError}
        label={t('input-label-phone')}
        required />
      <InputField
        handleOnChange={props.handleOnChange}
        value={props.mail}
        id="mail"
        type="email"
        valueError={props.mailError}
        label={t('input-label-mail')}
        required />
    </div>
    <div className="restaurant-info">
      <InputField
        handleOnChange={props.handleOnChange}
        value={props.message}
        id="message"
        type="textarea"
        label={t('input-label-message')} />
    </div>
  </form>;
}

function tableBooking(props, t) {
  return <form>
    <div className="restaurant-info">
      <InputField
        handleOnChange={props.handleOnChange}
        value={props.persons}
        id="persons"
        type="number"
        max={20}
        min={2}
        step={1}
        valueError={props.personsError}
        label={t('input-label-persons')}
        required />
    </div>
    <div className="restaurant-info">
      <InputField
        handleOnChange={props.handleOnChange}
        value={props.date}
        id="date"
        type="date"
        min={props.minDate}
        valueError={props.dateError}
        label={t('input-label-date')}
        required />
      <InputField
        handleOnChange={props.handleOnChange}
        value={props.time}
        id="time"
        type="time"
        valueError={props.timeError}
        label={t('input-label-time')}
        required />
    </div>
  </form>;
}

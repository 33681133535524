import React, { useState } from 'react'
import { Container } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import ContactFormInput from './contactFormInput'
import { submitContactMessage } from '../../utils/requestHelper'

import imageThanks from '../../images/thanks.jpg'

const ContactSection = (props) => {
  const {t} = useTranslation()

  const [data, setData ] = useState({
    givenname: null,
    surname: null,
    mail: null,
    address: null,
    phone: null,
    message: null,
    sendState: null,
    verificationState: null,
    mailError: null,
    givennameError: null,
    surnameError: null,
    messageError: null,
    iAgree: false
  })

  const handleOnChange = (e) => {
    const { id, value } = e.target
    setData(prevData => ({
        ...prevData,
        [id]: value
    }))
  }

  const handleOnChangeCheckbox = () => {
    setData(prevData => ({
        ...prevData,
        iAgree: !data.iAgree
    }))
  }

  const validateForm = () => {
    const errors = {
      mailError: null,
      phoneError: null,
      givennameError: null,
      surnameError: null,
      messageError: null,
      verificationState: true
    }

    if(!data.givenname){
      errors.givennameError = t('error-empty-givenname')
      errors.verificationState = false
    }
    if(!data.surname){
      errors.surnameError = t('error-empty-surname')
      errors.verificationState = false
    }
    if(!data.mail || data.mail.indexOf('@') === -1 || data.mail.indexOf('.') === -1){
      errors.mailError = t('error-empty-mail')
      errors.verificationState = false
    }
    if(!data.message){
      errors.messageError = t('error-empty-message')
      errors.verificationState = false
    }
    if(!data.phone){
      errors.phoneError = t('error-empty-phone')
      errors.verificationState = false
    }
    if(!data.iAgree){
      errors.verificationState = false
    }

    setData(prevData => ({
      ...prevData,
      ...errors
    }))
    return errors.verificationState
  }

  const handleOnSubmit = () => {
    if(validateForm()){
      setData(prevData => ({
        ...prevData,
        sendState: true
      }))
      submitContactMessage({
        "givenname":data.givenname,
        "surname":data.surname,
        "mail":data.mail,
        "phone": data.phone,
        "streetAddress": data.address,
        "message": data.message
      })
    }
  }

  if(!data.sendState)
    return(
      <section key={props.id} id={`#${props.id}`} className="discover-our-story">
        <Container>
          <div className="restaurant-info">
            <div className="global-headline">
              <h2 className="sub-headline">
                <span className="first-letter">{t('contact-sub-headline-firstletter')}</span>{t('contact-sub-headline')}
              </h2>
              <h1 className="headline headline-dark">{t('contact-headline')}</h1>
              <div className="asterisk"><i className="fas fa-asterisk"></i></div>
            </div>
          </div>
          <ContactFormInput
            handleOnChange={handleOnChange}
            handleOnSubmit={handleOnSubmit}
            handleOnChangeCheckbox={handleOnChangeCheckbox}
            data={data}
          />
        </Container>
      </section>
    );
  else
    return(
      <section key={props.id} id={`#${props.id}`} className="discover-our-story">
        <Container>
          <div className="restaurant-info">
            <div className="restaurant-description padding-right">
              <div className="global-headline">
                <h2 className="sub-headline">
                  <span className="first-letter">{t('contact-sub-headline-firstletter-success')}</span>{t('contact-sub-headline-success')}
                </h2>
                <h1 className="headline headline-dark">{t('contact-headline-success')}</h1>
                <div className="asterisk"><i className="fas fa-asterisk"></i></div>
              </div>
              <p>{t('contact-content-success')}</p>
            </div>
            <div className="restaurant-info-img">
              <img src={imageThanks} alt=""></img>
            </div>
          </div>
        </Container>
      </section>
    )
}

export default (ContactSection);
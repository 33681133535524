import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import { Buffer } from 'buffer'
import { Grid } from 'semantic-ui-react'

import defaultImage from '../../images/default-food.jpg'
import './offerSection.scss'

const OfferItem= (props) => {
  const {t} = useTranslation()

  const [backGroundImage, setBackgroundImage] = useState(defaultImage)

  useEffect(() => {
    if(props.hasImage){
      renderImage()
    }

  },[]);

  const renderImage = () => {
    if(props.hasImage){
      var auth = new Buffer(window._env_.REACT_APP_API_USER + ":" + window._env_.REACT_APP_API_PASSWORD).toString("base64")
      var options = {
        'method': 'GET',
        'url': `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/locations/${window._env_.REACT_APP_PRIMARY_LOCATION_CODE}/localOffers/${props.localOfferId}/image`,
        'headers': {
          Authorization: 'Basic ' + auth
        }
      }
      axios(options).then( function (response){
        var json = JSON.parse(response)
        setBackgroundImage(json.image)
      }).catch( function (error){
        console.log(error)
      })
    }
  }

  return(
    <Grid.Column md={4} className="pd-0 block-special">
      <div className="block-img special">
      <div className="background-img parallax" style={backGroundImage ? {backgroundImage: `url("${backGroundImage}")`} : {backgroundImage: `url("${(defaultImage)}")`}}>
        </div>
      </div>
      <div className={`block-content special sp-${props.sp}`} >
        <h2 style={{fontFamily: 'var(--secondary-font)', fontSize: '3.5rem', fontWeight: '400'}} className="mb-5">{props.name}</h2>
        <p>{props.description}</p>
        <span className="block-price special">{props.size && (`${props.size} | `)}{t(`currency-${props.currency}`)}{props.price}</span>
      </div>
    </Grid.Column>
  )
}

export default OfferItem
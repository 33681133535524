import React, { useEffect, useState } from 'react'
import { connect } from "react-redux"
import { GetRestaurantInfo, GetRestaurantMenuData, GetRestaurantMenuOffer, GetRestaurantOpeningTime, GetRestaurantActivitie, EnableLoading, DisableLoading } from "./redux/Actions/"
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { Main, About, Policy, Newsletter, RestaurantMenu, Reservation } from './pages'
import { Loader } from './components'
import moment from 'moment'
import { getDateFirstDayOfThisWeek, getDateLastDayOfThisWeek } from './utils/dateHelper'
import withClearCache from "./ClearCache"

import './App.scss'
import './fonts/index.css'

import ClosedPush from './components/notifications/closedPush'

const ClearCacheComponent = withClearCache(MainApp)

function App(props) {
  useEffect(() => {
    props.EnableLoading()
    setTimeout(() => {
      props.DisableLoading()
    },1800);

    props.GetRestaurantInfo()
    props.GetRestaurantMenuData()
    props.GetRestaurantMenuOffer(moment(getDateFirstDayOfThisWeek()).format('YYYY-MM-DD'),moment(getDateLastDayOfThisWeek()).format('YYYY-MM-DD'))
    props.GetRestaurantOpeningTime()
  },[])
  
  return <ClearCacheComponent />
}

function MainApp() {
  const [closedPush, setClosedPush] = useState(false)
  return (
    <BrowserRouter >
      <Loader />
      <ClosedPush
        open={closedPush}
        setOpen={setClosedPush}
      />
      <Routes>
        <Route path="/policy" element={<Policy />} />
        <Route path="/about" element={<About />} />
        <Route path="/newsletter/:state/:newsletterId" element={<Newsletter />} />
        <Route path="/reservation/:reservationId/:state" element={<Reservation />} />
        <Route path="/menu" element={<RestaurantMenu />} />
        <Route exact path="/" element={<Main />} />
      </Routes>
    </BrowserRouter>
  )
}

export default connect(null, {  EnableLoading, DisableLoading, GetRestaurantInfo, GetRestaurantMenuData, GetRestaurantMenuOffer, GetRestaurantOpeningTime, GetRestaurantActivitie })((App))
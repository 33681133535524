import React from 'react'
import { connect } from "react-redux"
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { InstallPWA, SliderSeparator, ContactSection, Footer, Header, Separator, MenuSection, OfferSection, NewsletterSection, ScrollHandler } from '../components'

import slide01 from './../images/slides/slide01.jpg'
import slide02 from './../images/slides/slide02.jpg'
import slide03 from './../images/slides/slide03.jpg'
import slide04 from './../images/slides/slide04.jpg'

import { MainSection, ReservationSection, HeroSection } from './sections'

const Main = (props) => {
  const {t} = useTranslation()
  const location = useLocation()
  return (
    <main>
      <ScrollHandler location={location} />
      <Header />
      <InstallPWA />
      <HeroSection
        id="hero"
        headline={props.restaurantInfo.name}
        color={'#3c1f00'}
        regularOpening={props.restaurantOpeningTime}
      />
      <MainSection />
      <Separator 
        class='tasteful-recipes'
        subHeadlineFirstLetter={t('separator-menu-sub-headline-firstletter')}
        subHeadline={t('separator-menu-sub-headline')}
        headline={t('separator-menu-headline')}
        color={'#3c1f00'}
        id="menu"
      />
      <MenuSection
        restaurantMenuData={props.restaurantMenuData}
        id="menu-section"
      />
      <Separator 
        class='special-offers'
        subHeadlineFirstLetter={t('separator-offers-sub-headline-firstletter')}
        subHeadline={t('separator-offers-sub-headline')}
        headline={t('separator-offers-headline')}
        color={'rgba(104, 76, 26, 0.85)'}
        id="offers"
      />
      <OfferSection
        restaurantMenuOffer={props.restaurantMenuOffer}
        id="offers-section"
      />
      <Separator 
        class='gift-card'
        subHeadlineFirstLetter={t('separator-reservation-sub-headline-firstletter')}
        subHeadline={t('separator-reservation-sub-headline')}
        headline={t('separator-reservation-headline')}
        color={'#3c1f00'}
        id="reservation"
      />
      <ReservationSection
        id="reservation-section"
        restaurantInfo={props.restaurantInfo}
        restaurantOpeningTime={props.restaurantOpeningTime}
      />
      <Separator 
        class='perfect-blend'
        subHeadlineFirstLetter={t('separator-contact-sub-headline-firstletter')}
        subHeadline={t('separator-contact-sub-headline')}
        headline={t('separator-contact-headline')}
        color={'#3c1f00'}
        id="contact" 
      />
      <ContactSection
        id="contact-section"
      />
      <SliderSeparator
        id="slider-section"
        restaurantStorys={[
          {
            localStoryId: "001",
            title: "Schlemmerwerk im Merkle",
            image: slide01
          },
          {
            localStoryId: "002",
            title: "Regionale Küche",
            image: slide02
          },
          {
            localStoryId: "003",
            title: "Garten Terrasse",
            image: slide03
          },
          {
            localStoryId: "004",
            title: "Feste Feiern",
            image: slide04
          }
        ]}
      />
      <NewsletterSection 
        id="newsletter" 
      />
      <Footer
        id="footer" 
        handleOnUp={"#hero"}
        restaurantOpeningTime={props.restaurantOpeningTime}
        restaurantInfo={props.restaurantInfo}
      />
    </main>
  );
}

const mapStateToProps = state => {
  return {
    restaurantMenuData: state.restaurantMenuData,
    restaurantOpeningTime: state.restaurantOpeningTime,
    restaurantInfo: state.restaurantInfo,
    restaurantMenuOffer: state.restaurantMenuOffer
  }
}

export default connect(mapStateToProps)((Main))
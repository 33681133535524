import { configureStore } from '@reduxjs/toolkit'
import rootReducer from "../Reducers/index"
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { thunk } from "redux-thunk";
 
const persistConfig = {
  key: 'SchlemmerApp',
  storage,
  blacklist: ['loading', 'toggleSidebar', 'isMobile']
}
const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: ()=> ([thunk]),
  devTools: process.env.NODE_ENV !== 'production'
})

export const persistor = persistStore(store)
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Container } from 'semantic-ui-react'
import { subscribeNewsletter } from '../../utils/requestHelper'
import { InputField } from '../'
import AnimatedButton from '../buttonAnimated'

const NewsletterSection = (props) => {
  const {t} = useTranslation()

  const [data, setData ] = useState({
    givenname: null,
    surname: null,
    mail: null,
    sendState: null,
    verificationState: null,
    mailError: null,
    givennameError: null,
    surnameError: null,
    iAgree: false
  })

  const handleOnChange = (e) => {
    const { id, value } = e.target
    setData(prevData => ({
        ...prevData,
        [id]: value
    }))
  }

  const handleOnChangeCheckbox = () => {
    setData(prevData => ({
        ...prevData,
        iAgree: !data.iAgree
    }))
  }
  

  const validateForm = () => {
    const errors = {
      mailError: null,
      givennameError: null,
      surnameError: null,
      verificationState: true
    }

    if(!data.givenname){
      errors.givennameError = t('error-empty-givenname')
      errors.verificationState = false
    }
    if(!data.surname){
      errors.surnameError = t('error-empty-surname')
      errors.verificationState = false
    }
    if(!data.mail || data.mail.indexOf('@') === -1 || data.mail.indexOf('.') === -1){
      errors.mailError = t('error-empty-mail')
      errors.verificationState = false
    }
    if(!data.iAgree){
      errors.verificationState = false
    }

    setData(prevData => ({
      ...prevData,
      ...errors
    }))
    return errors.verificationState
  }

  const handleOnSubmit = () => {
    if(validateForm()){
      setData(prevData => ({
        ...prevData,
        givenname: null,
        surname: null,
        mail: null,
        sendState: true
      }))
      subscribeNewsletter({"givenname":data.givenname,"surname":data.surname,"mail":data.mail})
    }
  }

  if(!data.sendState){
    return(
      <section key={props.id} id={`#${props.id}`} className="newsletter-section" style={{background: "rgba(187, 166, 154, .85)"}}>
        <Container fluid >
          <Grid stackable columns={2}>
            <Grid.Column>
              <div className="restaurant-description padding-right">
                <div className="global-headline">
                  <h2 className="sub-headline">
                    <span className="first-letter">{t('newsletter-sub-headline-firstletter')}</span>{t('newsletter-sub-headline')}
                  </h2>
                  <div className="asterisk"><i className="fas fa-asterisk"></i></div>
                </div>
                <p style={{textAlign:'center'}}>
                  {t('newsletter-content')}
                </p>  
              </div>
            </Grid.Column>
            <Grid.Column>
              <form>
                <div className="restaurant-info">
                  <InputField 
                    handleOnChange={handleOnChange}
                    value={data.givenname}
                    id="givenname"
                    type="text"
                    valueError={data.givennameError}
                    label={t('input-label-givenname')}
                    required
                  />
                  <InputField 
                    handleOnChange={handleOnChange}
                    value={data.surname}
                    id="surname"
                    type="text"
                    valueError={data.surnameError}
                    label={t('input-label-surname')}
                    required
                  />
                </div>
                <div className="restaurant-info">
                  <InputField 
                    handleOnChange={handleOnChange}
                    value={data.mail}
                    id="mail"
                    type="mail"
                    valueError={data.mailError}
                    label={t('input-label-mail')}
                    required
                  />
                </div>
                <div className="restaurant-left">
                  <InputField 
                    handleOnChange={handleOnChangeCheckbox}
                    id="iAgree"
                    type="checkbox"
                    label={t('contact-form-iAgree')}
                    required
                  />
                </div>
                <div className="restaurant-right">
                  <AnimatedButton
                    text={t('newsletter-form-submit')}
                    onClick={handleOnSubmit}
                  />
                </div>
              </form>
            </Grid.Column>
          </Grid>
        </Container>
      </section>
    );
  }
  else {
    return (
      <section className="newsletter-section" style={{background:"rgba(187, 166, 154, .85)"}}>
        <div className="container">
          <div className="restaurant-info">
            <div className="global-headline">
              <h2 className="sub-headline">
                <span className="first-letter">{t('newsletter-sub-headline-firstletter-success')}</span>{t('newsletter-sub-headline-success')}
              </h2>
              <h1 className="headline headline-dark">{t('newsletter-headline-success')}</h1>
              <div className="asterisk"><i className="fas fa-asterisk"></i></div>
              <div><p>{t('newsletter-content-success')}</p></div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default NewsletterSection
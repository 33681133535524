import React, { useEffect, useState } from 'react'
import { connect } from "react-redux"
import { GetRestaurantInfo, GetRestaurantOpeningTime, GetRestaurantActivitie } from "../redux/Actions/"
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Container } from 'semantic-ui-react'
import { verifyNewsletter, unsubscribeNewsletter } from '../utils/requestHelper'
import moment from 'moment'
import { getDateFirstDayOfThisWeek } from '../utils/dateHelper'
import { Footer, Header, Separator } from '../components'

const Newsletter = (props) => {
  const {t} = useTranslation()
  const params = useParams()
  const [ data, setData ] = useState({})

  useEffect(() => {
    const { newsletterId, state } = params
    if(newsletterId && state){
      setData({
        newsletterId: newsletterId,
        state: state
      })
    }
    props.GetRestaurantOpeningTime()
    props.GetRestaurantActivitie(moment(getDateFirstDayOfThisWeek()).format('YYYY-MM-DD'),2)
    props.GetRestaurantInfo()
  },[params]);

  const renderNewsletterState = () => {

    switch (data.state) {
      case 'unsubscribe':
        unsubscribeNewsletter(data.newsletterId)
        return(
          <div className="restaurant-info">
            <div className="global-headline">
              <h2 className="sub-headline">
                <span className="first-letter">{t('newsletter-sub-headline-firstletter-unsubscribe')}</span>{t('newsletter-sub-headline-unsubscribe')}
              </h2>
              <h1 className="headline headline-dark">{t('newsletter-headline-unsubscribe')}</h1>
              <div className="asterisk"><i className="fas fa-asterisk"></i></div>
              <div><p>{t('newsletter-page-content-unsubscribe')}</p></div>
            </div>
          </div>
        )
      case 'verify':
        verifyNewsletter(data.newsletterId)
        return(
          <div className="restaurant-info">
            <div className="global-headline">
              <h2 className="sub-headline">
                <span className="first-letter">{t('newsletter-sub-headline-firstletter-success')}</span>{t('newsletter-sub-headline-success')}
              </h2>
              <h1 className="headline headline-dark">{t('newsletter-headline-success')}</h1>
              <div className="asterisk"><i className="fas fa-asterisk"></i></div>
              <div><p>{t('newsletter-page-content-success')}</p></div>
            </div>
          </div>
        )
      default:
        break
    }
  }
  return (
    <main>
      <Header />
      <Separator 
        class='tasteful-hero'
        anchor={'our-about'}
      />
      <section className="newsletter-page">
        <Container>
          {renderNewsletterState()}
        </Container>
      </section>
      <Footer
        id="footer"
        handleOnUp={"/"}
        restaurantOpeningTime={props.restaurantOpeningTime}
        restaurantInfo={props.restaurantInfo}
      />
    </main>
  );
}

const mapStateToProps = state => {
  return {
    restaurantOpeningTime: state.restaurantOpeningTime,
    restaurantActivitie: state.restaurantActivitie,
    restaurantInfo: state.restaurantInfo
  };
};

export default connect(mapStateToProps,{GetRestaurantInfo, GetRestaurantOpeningTime, GetRestaurantActivitie})(Newsletter)
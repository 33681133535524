import React from 'react'
import './button.scss'

const AnimatedButton = (props) => {
  return(
    <a className='animated-arrow' onClick={props.onClick}>
      <span className='the-arrow -left'>
        <span className='shaft'></span>
      </span>
      <span className='main'>
        <span className='text'>{props.text}</span>
        <span className='the-arrow -right'>
          <span className='shaft'></span>
        </span>
      </span>
    </a>
  )
}

export default AnimatedButton
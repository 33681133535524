import { GET_RESTAURANT_MENU_DATA, GET_RESTAURANT_MENU_OFFER, GET_RESTAURANT_OPENING_TIME, GET_RESTAURANT_ACTIVITE, GET_RESTAURANT_INFO } from "../Constants/action-types"
import axios from 'axios';
import { Buffer } from 'buffer';
var auth = new Buffer(window._env_.REACT_APP_API_USER + ":" + window._env_.REACT_APP_API_PASSWORD).toString("base64")

export function GetRestaurantMenuData(){
  return function(dispatch){
    var options = {
      'method': 'GET',
      'url': `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/locations/${window._env_.REACT_APP_PRIMARY_LOCATION_CODE}/foodMenus?state=active`,
      'headers': {
        Authorization: 'Basic ' + auth
      }
    }
    axios(options).then( function (response){
      return dispatch({ type: GET_RESTAURANT_MENU_DATA, payload: response.data });
    }).catch( function (error){
      console.log(error)
    })
  }
}

export function GetRestaurantMenuOffer(dateFrom, dateTo){
  return function(dispatch){
    var options = {
      'method': 'GET',
      'url': `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/locations/${window._env_.REACT_APP_PRIMARY_LOCATION_CODE}/localOffers?dateFrom=${dateFrom}&dateTo=${dateTo}`,
      'headers': {
        Authorization: 'Basic ' + auth
      }
    }
    axios(options).then( function (response){
      return dispatch({ type: GET_RESTAURANT_MENU_OFFER, payload: response.data });
    }).catch( function (error){
      console.log(error)
    })
  }
}

export function GetRestaurantOpeningTime(){
  return function(dispatch){
    var options = {
      'method': 'GET',
      'url': `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/locations/${window._env_.REACT_APP_PRIMARY_LOCATION_CODE}/regularHours`,
      'headers': {
        Authorization: 'Basic ' + auth
      }
    }
    axios(options).then( function (response){
      return dispatch({ type: GET_RESTAURANT_OPENING_TIME, payload: response.data });
    }).catch( function (error){
      console.log(error)
    })
  }
}

export function GetRestaurantActivitie(dateFrom, limit){
  return function(dispatch){
    var options = {
      'method': 'GET',
      'url': `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/locations/${window._env_.REACT_APP_PRIMARY_LOCATION_CODE}/localPosts?dateFrom=${dateFrom}&limit=${limit}`,
      'headers': {
        Authorization: 'Basic ' + auth
      }
    }
    axios(options).then( function (response){
      return dispatch({ type: GET_RESTAURANT_ACTIVITE, payload: response.data });
    }).catch( function (error){
      console.log(error)
    })
  }
}

export function GetRestaurantInfo(){
  return function(dispatch){
    var options = {
      'method': 'GET',
      'url': `${window._env_.REACT_APP_API_URL}/${window._env_.REACT_APP_API_VERSION}/locations/${window._env_.REACT_APP_PRIMARY_LOCATION_CODE}`,
      'headers': {
        Authorization: 'Basic ' + auth
      }
    }
    axios(options).then( function (response){
      return dispatch({ type: GET_RESTAURANT_INFO, payload: response.data });
    }).catch( function (error){
      console.log(error)
    })
  }
}
import React from 'react'
import { connect } from "react-redux";
import { GetRestaurantInfo, GetRestaurantOpeningTime, GetRestaurantActivitie } from "../redux/Actions/";
import { useTranslation } from 'react-i18next'
import { Container } from 'semantic-ui-react'
import { Footer, Header, Separator } from '../components'

const Policy = (props) => {
  const {t} = useTranslation()

  return (
    <main>
      <Header />
      <Separator 
        class='tasteful-hero'
        anchor={'our-about'}
      />
      <section className="our-about">
        <Container>
          <h1 className="headline headline-dark">{t('page-policy-headline')}</h1>
          <h2 className="headline-dark">{t('page-policy-headline-information')}</h2>
          <p>{t('page-policy-information')}</p>
          <h2 className="headline-dark">{t('page-policy-elevation-headline')}</h2>
          <p>{t('page-policy-elevation-content-01')}</p>
          <p>{t('page-policy-elevation-content-02')}</p>
          <p>{t('page-policy-elevation-content-03')}</p>
          <h2 className="headline-dark">{t('page-policy-collection-headline')}</h2>
          <p>{t('page-policy-collection-content-01')}</p>
          <p>
            <ul>
              <li>{t('page-policy-collection-content-ul-01')}</li>
              <li>{t('page-policy-collection-content-ul-02')}</li>
              <li>{t('page-policy-collection-content-ul-03')}</li>
              <li>{t('page-policy-collection-content-ul-04')}</li>
              <li>{t('page-policy-collection-content-ul-05')}</li>
              <li>{t('page-policy-collection-content-ul-06')}</li>
              <li>{t('page-policy-collection-content-ul-07')}</li>
            </ul>
          </p>
          <p>{t('page-policy-collection-content-02')}</p>
          <h2 className="headline-dark">{t('page-policy-contact-headline')}</h2>
          <p>{t('page-policy-contact-content')}</p>
          <h2 className="headline-dark">{t('page-policy-order-headline')}</h2>
          <p>{t('page-policy-order-content')}</p>
          <p>{t('page-policy-order-content-01')}</p>
          <p>{t('page-policy-order-content-01-dhl')}</p>
          <p>{t('page-policy-order-content-01-dpd')}</p>
          <p>{t('page-policy-order-content-01-ups')}</p>
          <p>{t('page-policy-order-content-02')}</p>
          <p>{t('page-policy-order-content-02-paypal')}</p>
          <h2 className="headline-dark">{t('page-policy-newsletter-headline')}</h2>
          <p>{t('page-policy-newsletter-content')}</p>
          <p>{t('page-policy-newsletter-content-01')}</p>
          <h2 className="headline-dark">{t('page-policy-guest-registration-headline')}</h2>
          <p>{t('page-policy-guest-registration-content')}</p>
          <p>{t('page-policy-guest-registration-content-01')}</p>
          <p>{t('page-policy-guest-registration-content-02')}</p>
          <p>{t('page-policy-guest-registration-content-03')}</p>
          <h2 className="headline-dark">{t('page-policy-plugins-headline')}</h2>
          <p>{t('page-policy-plugins-content')}</p>
          <p>{t('page-policy-plugins-content-01')}</p>
          <p>{t('page-policy-plugins-content-maps')}</p>
          <p>{t('page-policy-plugins-content-recaptcha')}</p>
        </Container>
      </section>
      <Footer
        id="footer"
        handleOnUp={"/about/#our-about"}
        restaurantOpeningTime={props.restaurantOpeningTime}
        restaurantInfo={props.restaurantInfo}
      />
    </main>
  );
}

const mapStateToProps = state => {
  return {
    restaurantOpeningTime: state.restaurantOpeningTime,
    restaurantActivitie: state.restaurantActivitie,
    restaurantInfo: state.restaurantInfo
  };
};

export default connect(mapStateToProps,{GetRestaurantInfo, GetRestaurantOpeningTime, GetRestaurantActivitie})((Policy))
import React, { useEffect, useState } from 'react'
import { connect } from "react-redux"
import { GetRestaurantInfo, GetRestaurantOpeningTime, GetRestaurantActivitie } from "../redux/Actions"
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Container, Grid } from 'semantic-ui-react'
import { getReservation, updateReservation } from '../utils/requestHelper'
import moment from 'moment'
import { getDateFirstDayOfThisWeek } from '../utils/dateHelper'
import { Footer, Header, Separator } from '../components'

const Reservation = (props) => {
  const {t} = useTranslation()
  const params = useParams()
  const [ data, setData ] = useState({})
  const [ reservation, setReservation ] = useState({})

  useEffect(() => {
    const { reservationId, state } = params
    const fetchData = async (reservationId, state) => {
      await updateReservation(reservationId, state)
      setData(({
        reservationId: reservationId,
        state: state
      }))
    }
    if(reservationId && state)
      fetchData(reservationId, state)
    
    props.GetRestaurantOpeningTime()
    props.GetRestaurantActivitie(moment(getDateFirstDayOfThisWeek()).format('YYYY-MM-DD'),2)
    props.GetRestaurantInfo()
  },[params]);

  useEffect(() => {
    const fetchData = async (data) => {
      var result = await getReservation(data)
      if(result)
        setReservation(result)
    }
    if(data.hasOwnProperty('reservationId'))
      fetchData(data.reservationId)
  }, [data])

  return (
    <main>
      <Header />
      <Separator 
        class='tasteful-hero'
        anchor={'our-about'}
      />
      <section className="reservation-page">
        <Container>
          <Grid stackable>
            <Grid.Column width={6}>        
              <div className="restaurant-description padding-right">
                <div className="global-headline">
                  <h2 className="sub-headline">
                    <span className="first-letter">{t('reservation-section-sub-headline-firstletter')}</span>{t('reservation-section-sub-headline')}
                  </h2>
                  <h1 className="headline headline-dark">{t('reservation-section-headline')}</h1>
                </div>
              </div>
            </Grid.Column>
            <Grid.Column width={10} textAlign='left' verticalAlign='middle'>
              <p>{t('reservation-section-content')}</p>
              {reservation.hasOwnProperty('state') && <p style={{marginBottom:5}} ><span style={{fontWeight: 900}}>{t(`reservation-section-state`)}: </span>{t(`reservation-section-state-${reservation.state}`)}</p>}
              {reservation.hasOwnProperty('givenname') && <p style={{marginBottom:15}} ><span style={{fontWeight: 900}}>{t(`reservation-section-name`)}: </span>{`${reservation.givenname}, ${reservation.surname}`}</p>}
              {reservation.hasOwnProperty('date') && <p style={{marginBottom:5}} ><span style={{fontWeight: 900}}>{t(`reservation-section-date`)}: </span>{`${moment(new Date(reservation.date)).format('DD.MM.YYYY')}, ${reservation.time}`}</p>}
              {reservation.hasOwnProperty('persons') && <p style={{marginBottom:15}} ><span style={{fontWeight: 900}}>{t(`reservation-section-persons`)}: </span>{`${reservation.persons}`}</p>}
            </Grid.Column>
          </Grid>
        </Container>
      </section>
      <Footer
        id="footer"
        handleOnUp={"/"}
        restaurantOpeningTime={props.restaurantOpeningTime}
        restaurantInfo={props.restaurantInfo}
      />
    </main>
  );
}

const mapStateToProps = state => {
  return {
    restaurantOpeningTime: state.restaurantOpeningTime,
    restaurantActivitie: state.restaurantActivitie,
    restaurantInfo: state.restaurantInfo
  };
};

export default connect(mapStateToProps,{GetRestaurantInfo, GetRestaurantOpeningTime, GetRestaurantActivitie})(Reservation)
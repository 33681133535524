import React from 'react'
import { Transition, Header, Modal, Image } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import image from "../../images/apple-touch-icon.png";
import './notifications.scss'

const ClosedPush = (props) => {
  const {t} = useTranslation()
  const style = {
    header:{
      textAlign: "center",
      fontSize: "1.9rem",
      textTransform: "uppercase"
    }, 
    content: {
      textAlign: "center",
    },
    small: {
      textAlign: "center",
      fontSize: "1rem"
    },
    image: {
      transform: "translate(0, -50%)"
    },
    shareImg : {
      margin: "0px 5px 0px 5px",
      height: "10px",
      width: "10px"
    },
    btn: {
      textAlign: "center",
      textTransform: "uppercase",
      color: "rgba(0, 85, 179, 1)",
      cursor: "pointer"
    },
    appleShare: {
      color: "rgba(0, 85, 179, 1)",
      fill:"rgba(0, 85, 179, 1)",
      margin: "0px 5px 0px 5px",
      height: "15px",
      width: "15px"
    }
  }

  return(
    <Transition.Group animation={'fly down'} duration={1500}>
      <Modal
        size={'mini'}
        open={props.open}
        onClose={() => props.setOpen(false)}
        onOpen={() => props.setOpen(true)}
      >
      <Modal.Content>
        <Modal.Description>
          <Image style={style.image} src={image} size='tiny' centered />
          <Header style={style.header} >{t('push-closed-header')}</Header>
          <p style={style.content} >{t('push-closed-content')}</p>
          <div>
            <p style={style.btn} >
              <span onClick={()=>props.setOpen(false)} >{t('button-close')}</span>
            </p>
          </div>
        </Modal.Description>
      </Modal.Content>
      </Modal>
    </Transition.Group>
  )
}

export default ClosedPush 
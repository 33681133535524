import React, { useState } from 'react'
import { Container, Grid, Icon } from 'semantic-ui-react'
import { ReservationPush, AnimatedButton } from '../../components'
import { useTranslation } from 'react-i18next'
import { isRestaurantOpenAtDateTime } from '../../utils/dateHelper'
import { createReservation } from '../../utils/requestHelper'

const ReservationSection = (props) => {
  const {t} = useTranslation()

  const [ bookingStep, setBookingStep ] = useState(1)
  const [ showBooking, setShowBooking ] = useState(false)
  const [data, setData ] = useState({
    givenname: null,
    surname: null,
    mail: null,
    phone: null,
    persons: null,
    date: null,
    time: null,
    message: null,
  })

  const handleOnChange = (e) => {
    const { id, value } = e.target
    setData(prevData => ({
        ...prevData,
        [id]: value
    }))
  }

  const handleOnClickNextBooking = async() => {
    if(bookingStep === 1)
      setBookingStep(2)
    if(bookingStep === 2) {
      if(validateForm())
        if(createReservation(data))
          setBookingStep(3)
        else
          setBookingStep(4)
      else
        setBookingStep(4)
    }
    if(bookingStep === 3) 
      handleOnClickCancelBooking()
    if(bookingStep === 4)
      setBookingStep(1)
  }

  const handleOnClickCancelBooking = () => {
    setShowBooking(false)
    setData({
      givenname: null,
      surname: null,
      mail: null,
      phone: null,
      persons: null,
      date: null,
      time: null,
      message: null,
    })
    setBookingStep(1)
  }

  const validateForm = () => {
    const errors = {
      mailError: null,
      phoneError: null,
      givennameError: null,
      surnameError: null,
      dateError: null,
      timeError: null,
      personsError: null,
      verificationState: true
    }

    if(!data.date || data.date < (new Date((new Date()).setDate(new Date().getDate()+1))).toISOString().slice(0, 10)){
      errors.dateError = t('error-empty-date')
      errors.verificationState = false
    }
    if(!data.time){
      errors.dateError = t('error-empty-time')
      errors.verificationState = false
    }
    if(!data.persons){
      errors.dateError = t('error-empty-persons')
      errors.verificationState = false
    }
    if(!data.givenname){
      errors.givennameError = t('error-empty-givenname')
      errors.verificationState = false
    }
    if(!data.surname){
      errors.surnameError = t('error-empty-surname')
      errors.verificationState = false
    }
    if(!data.mail || data.mail.indexOf('@') === -1 || data.mail.indexOf('.') === -1){
      errors.mailError = t('error-empty-mail')
      errors.verificationState = false
    }
    if(!data.phone){
      errors.phoneError = t('error-empty-phone')
      errors.verificationState = false
    }

    if(errors.verificationState === true) {
      if(!isRestaurantOpenAtDateTime(props.restaurantOpeningTime, data.date, data.time))
        errors.verificationState = false
    }

    setData(prevData => ({
      ...prevData,
      ...errors
    }))
    return errors.verificationState
  }

  const renderOpening = () => {
    var items = []
    var arrayObj = []
    props.restaurantOpeningTime.forEach(element => {
      var dayString = ''
      var timeString = `${element.openTime} Uhr - ${element.closeTime} Uhr`
      if(element.openDay !== element.closeDay)
        dayString = t(element.openDay) + " - " + t(element.closeDay)
      else
        dayString = t(element.openDay)

      var index = arrayObj.findIndex(x => x.name===dayString)
      if(index === -1)
        arrayObj.push({name:dayString, value:[timeString]})
      else
        arrayObj[index].value.push(timeString)
    })
    arrayObj.forEach((element,index) => {
      items.push(<div style={{marginBottom: 25}} key={`opening_day_${index}`}>
        <h2>{element.name}</h2>
        {renderArrayValue(element.value, index)}</div>
      )
    });
    return items
  }

  const renderArrayValue = (value, index) => {
    var items = []
    value.forEach(element => {
      items.push(
        <>
          <Icon.Group key={`opening_time_${index}`}>
            <Icon color='white' name='clock outline'/>{element}<br/>
          </Icon.Group><br/>
        </>
      )
    });
    return items
  }

  return(
    <section key={props.id} id={`#${props.id}`} className="discover-our-story">
      <Container>
        <Grid stackable columns={2}>
          <Grid.Column>        
            <div className="restaurant-description padding-right">
              <div className="global-headline">
                <h2 className="sub-headline">
                  <span className="first-letter">{t('info-reservation-section-sub-headline-firstletter')}</span>{t('info-reservation-section-sub-headline')}
                </h2>
                <h1 className="headline headline-dark">{t('info-reservation-section-headline')}</h1>
              </div>
              <p className={'restaurant-info'}>{t('info-reservation-section-content')}</p>
              <div className="button-row">
                <AnimatedButton
                  text={t('button-booking')}
                  onClick={() => setShowBooking(true)}
                />
              </div>
            </div>
          </Grid.Column>
          <Grid.Column verticalAlign='middle' textAlign='center'>
            <div className="restaurant-description padding-right" >
              <h3 className="headline headline-dark" style={{textTransform: "uppercase", fontSize: '2rem'}}>{t('footer-content-opening-headline')}</h3>
              {props.restaurantOpeningTime && renderOpening()}
            </div>
          </Grid.Column>
        </Grid>
      </Container>
      <ReservationPush
        open={showBooking}
        handleOnChange={handleOnChange}
        onClickNext={handleOnClickNextBooking}
        onClickCancel={handleOnClickCancelBooking}
        step={bookingStep}
        givenname={data.givenname}
        surname={data.surname}
        mail={data.mail}
        phone={data.phone}
        persons={data.persons}
        date={data.date}
        minDate={(new Date((new Date()).setDate(new Date().getDate()+1))).toISOString().slice(0, 10)}
        time={data.time}
        message={data.message}
      />
    </section>
  )
}

export default ReservationSection